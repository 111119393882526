<template>
  <b-card no-body>
    <b-card-body>
      <b-row
        v-if="deviceItem.id"
        class="d-flex align-items-center"
      >
        <b-col>
          <div class="d-flex">
            <b-button
              class="mr-1"
              variant="primary"
              :disabled="!deviceItem.device_info"
              @click="setModal(deviceItem.device_info)"
            >
              <FeatherIcon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
            <div class="border-left pl-1">
              <div class="font-small-2 text-primary">
                {{ deviceItem.company }}
              </div>
              <div>{{ deviceItem.customer_user }}</div>
              <div class="font-small-2 text-muted">
                {{ deviceItem.uuid }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col>
          <div class="font-weight-bold text-primary text-right font-medium-2">
            {{ moment(deviceItem.opened).format('LLLL') }}
          </div>
        </b-col>
      </b-row>
      <b-alert
        v-else
        show
        variant="danger"
      >
        <div class="alert-body text-center">
          Son oturum açma bilgisi bulunamadı. Cihaz bilgisi sıfırlanmış veya henüz hiç oturum açılmamış.
        </div>
      </b-alert>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(uuid)="data">
        <div class="font-small-2 text-primary">
          {{ data.item.company }}
        </div>
        <div>
          {{ data.item.customer_user }}
        </div>
        <div class="font-small-2 text-muted">
          {{ data.item.uuid }}
        </div>
      </template>
      <template #cell(status)="data">
        <div class="d-flex align-items-center">
          <b-button
            class="btn-icon mr-1"
            :variant="data.item.status === '1'? 'success' : 'danger'"
            pill
            :disabled="!data.item.device_info"
            @click="setModal(data.item.device_info)"
          >
            <FeatherIcon :icon="data.item.status === '1'? 'CheckCircleIcon' : 'XCircleIcon'" />
          </b-button>
          <div>
            <div
              v-if="data.item.status === '1'"
              class="text-success"
            >
              Giriş Başarılı!
            </div>
            <div
              v-else
              class="text-danger"
            >
              Oturum Açma Hatası!
            </div>
          </div>
        </div>
      </template>
      <template #cell(created)="data">
        <div>
          {{ moment(data.item.created).format('llll') }}
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-row>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            variant="outline-danger"
            :disabled="!deviceItem.id"
            @click="resetDevice"
          >
            <FeatherIcon icon="TrashIcon" />
            Cihaz Sıfırla
          </b-button>
        </b-col>
      </b-row>
    </b-card-footer>
    <b-modal
      v-model="detailModal.status"
      title="Cihaz Bilgisi"
      centered
      size="lg"
      no-close-on-esc
      hide-footer
    >
      <b-list-group>
        <b-list-group-item
          v-for="(item,key) in detailModal.data"
          :key="key"
        >
          <div class="font-weight-bold font-small-2 text-primary">
            {{ key }}
          </div>
          <div>{{ item }}</div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BCardFooter, BTable, BButton, BModal, BListGroup, BListGroupItem, BRow, BCol, BPagination, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'UserLogs',
  components: {
    BPagination,
    BCard,
    BCardBody,
    BCardFooter,
    BTable,
    BButton,
    BModal,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BAlert,
  },
  data() {
    return {
      detailModal: {
        status: false,
        data: {},
      },
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'uuid',
          label: 'Cihaz NO',
        },
        {
          key: 'status',
          label: 'İŞLEM DURUMU',
          thClass: 'width-250 text-nowrap',
          tdClass: 'width-250 text-nowrap',
        },
        {
          key: 'created',
          label: 'İŞLEM TARİHİ',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-50 text-nowrap',
          tdClass: 'width-50 text-nowrap',
        },
      ],
      dataQuery: {
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    deviceItem() {
      return this.$store.getters['customerAccounts/deviceItem']
    },
    dataList() {
      return this.$store.getters['customerAccounts/authLogs']
    },
    dataCounts() {
      return this.$store.getters['customerAccounts/authLogCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    setModal(modalData) {
      this.detailModal = {
        status: !this.detailModal.status,
        data: modalData,
      }
    },
    getDataList() {
      this.$store.dispatch('customerAccounts/getAuthLogs', {
        id: this.$route.params.id,
        limit: this.dataQuery.limit,
        start: this.dataQuery.start,
      })
    },
    resetDevice() {
      this.$store.dispatch('customerAccounts/resetDevice', {
        id: this.deviceItem.id_customer_accounts,
      })
    },
  },
}
</script>
