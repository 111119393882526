<template>
  <b-tabs
    pills
    fill
  >
    <b-tab active>
      <template #title>
        <feather-icon icon="InfoIcon" />
        <span>Hesap Bilgileri</span>
      </template>
      <validation-observer ref="simpleRules">
        <item-form />
        <save-button
          :save-action="submitForm"
          :is-disabled="submitStatus"
        />
      </validation-observer>
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="ShieldIcon" />
        <span>Hesap Hareketleri</span>
      </template>
      <user-logs />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Müşteri Bilgileri</span>
      </template>
      <customer-info />
      <customer-invoice />
    </b-tab>
    <b-tab lazy>
      <template #title>
        <feather-icon icon="ClockIcon" />
        <span>Müşteri Geçmişi</span>
      </template>
      <customer-history :customer-id="this.$route.params.id_customers" />
    </b-tab>
  </b-tabs>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ItemForm from '@/views/Admin/Customer_accounts/ItemForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CustomerHistory from '@/views/Admin/Customers/View/CustomerHistory.vue'
import CustomerInfo from '@/views/Admin/Customers/View/CustomerInfo.vue'
import CustomerInvoice from '@/views/Admin/Customers/View/CustomerInvoice.vue'
import UserLogs from '@/views/Admin/Customer_accounts/UserLogs.vue'

export default {
  name: 'Add',
  components: {
    BTabs,
    BTab,
    ItemForm,
    SaveButton,
    UserLogs,
    CustomerInvoice,
    CustomerInfo,
    CustomerHistory,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
    saveData() {
      return this.$store.getters['customerAccounts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('customerAccounts/getDataItem', this.$route.params.id)
        .then(response => {
          this.$store.dispatch('customers/getDataItem', response.id_customers)
        })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('customerAccounts/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
